.hideOnMobile {
  display: none;
}

.showOnMobile {
  display: flex;
}
.auth-links {
  border-left: none;
}
.leftBordered {
  border-left: solid 2px black;
  padding-left: 16px;
}
.rightPadded {
  padding-right: 16px;
}

@media (min-width: 770px) {
  .hideOnMobile {
    display: flex;
  }

  .showOnMobile {
    display: none;
  }
  .auth-links {
    border-left: solid 1px black;
  }
}

.notificationBadgeWrapper {
  position: relative;
  display: flex;
}

.logout-button {
  display: inline-block;
  position: relative;
}

.hover-helper-text {
  position: absolute;
  white-space: nowrap;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #242424;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.logout-button:hover .hover-helper-text {
  opacity: 0.7;
}

@media (max-width: 675px) {
  .main-admin-nav-burger-menu-wrapper {
    display: block;
    padding: 0.5rem;
    position: absolute;

    .menu-button-burger-admin {
      display: block;
      position: relative;
      bottom: 1rem;
      right: 2rem;

      .navlinks-dropdown-container-admin {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        top: 2rem;
        right: 0;
        padding: 0 0 5rem 0;
        transform: translateY(10px);
        animation: fadeOut 0.25s forwards;
      }

      .burger-menu-resp-link {
        display: flex;
        height: 25px;
        align-items: center;
        margin: 0.25rem 0;
        white-space: nowrap;
      }
      .flex-gap-resp-burger {
        gap: 0.5rem;
      }

      .logout-button-admin-resp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
      }

      .burger-main-container-admin {
        @keyframes fadeOut {
          0% {
            opacity: 0;
            visibility: hidden;
          }
          100% {
            opacity: 1;
            visibility: visible;
            padding: 1rem 2rem;
            background-color: #ffff;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

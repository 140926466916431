.form {
  width: 50%;
  margin-left: 25%;
}

.form-layer {
  position: relative;
  margin-top: 80px;
}

.qr-max-size {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  max-width: 600px;
  max-height: 360px;
}

.ticket-font {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 1.55rem;
}
@media (max-width: 640px) {
  .ticket-font {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 0.85rem;
  }

  .qr-max-size {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    padding: 0.15rem;
    width: 100%;
  }
}

.input-select-container-addsub-tool {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-width: 1px;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  width: 100%;
  margin-top: 10px;

  select {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    outline: none;
  }
}

.detail-card-more-info-container {
  display: flex;
  gap: 20px;
  justify-content: flex;
  color: #21840e;
  padding: 15px 0px;
  flex-wrap: wrap;

  .card {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 250px;

    span {
      font-size: 2.25rem;
      font-weight: 600;
    }
  }
}

@media (max-width: 671px) {
  .detail-card-more-info-container {
    justify-content: center;
  }
}

.landing-main-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .top-section {
    display: flex;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    img.vector-landing-top {
      position: absolute;
      bottom: 0.3px;
      opacity: 0.7;
      z-index: 1;
    }

    .content {
      position: absolute;
      width: 100%;
      top: 20%;
      left: 50%;
      transform: translate(-50%, 0%);
      display: flex;
      padding: 30px;
      justify-content: flex-end;

      .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        position: relative;
        max-width: 650px;

        img.laptop-cellphone {
          width: 100%;
        }

        .row {
          h2 {
            font-size: 2rem;
            font-weight: 700;
            color: #5a626f;
            font-family: Raleway;
          }

          h3 {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 28px;
            font-family: Raleway;
            color: #5a626f;
          }

          p,
          label {
            font-family: "Raleway";
            font-weight: 500;
            font-size: 0.875rem;
            color: #5a626f;
          }

          img.softrestaurant {
            height: 2rem;
            width: auto;
          }
        }
        .form-main-container {
          margin-top: 10px;
          .form-container {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 10px;

            .input-email-container {
              width: 100%;
              border-radius: 10px;
              background-color: #ffffff;
              input {
                width: 100%;
                font-size: 1rem;
                background-color: transparent;
                border-radius: 10px;
                padding: 12px;
                outline: none;
              }
            }

            button {
              font-size: 1rem;
              color: #ffffff;
              font-weight: 700;
              padding: 12px 36px;
              background-color: #5bac43;
              border-radius: 10px;
              outline: none;
            }
          }
        }
      }

      .container.image-laptop-cellphone {
        position: absolute;
        width: 50%;
        left: 4%;
        max-width: none;
      }
    }
  }

  .second-section {
    margin-top: 40px;
    width: 100%;
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      h2 {
        font-family: Raleway;
        font-size: 2.25rem;
        text-align: center;
        font-weight: 700;
        color: #5a626f;
        margin: 30px 0px;
      }

      h3 {
        font-size: 1rem;
        color: #5a626f;
        font-weight: 400;
        text-align: center;
        max-width: 740px;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        p {
          text-align: center;
          color: #5a626f;
          font-size: 1rem;
          max-width: 320px;
        }

        .division {
          width: 2px;
          height: 3rem;
          background-color: #5a626f;
        }
      }

      .mySwiper {
        width: 100%;
        height: 100%;
        padding-bottom: 40px;
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          width: 100%;

          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;

          .swiper-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 60px;
            margin-top: 40px;
            flex-wrap: wrap;
            .item {
              max-width: 280px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 10px;

              img {
                width: 170px;
              }

              h3,
              p {
                font-size: 1.5rem;
                color: #5a626f;
                font-weight: 700;
                text-align: center;
              }

              p {
                font-size: 1rem;
                font-weight: 400;
              }
            }
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 24px;
            height: 24px;
            margin: 0 10px;
          }

          .swiper-pagination-bullet-active {
            background: #5bac43;
          }
        }
      }
    }

    .info-row {
      margin-top: 15px;
    }
  }

  .third-section {
    margin-top: 40px;
    padding: 20px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.06);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 28px;
      font-family: Raleway;
      color: #5a626f;
      text-align: center;
    }

    .images-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }
  }

  .fourth-section {
    background-color: #c2e9b5;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
    position: relative;

    .iphone {
      width: 100%;
      position: absolute;
      left: 25%;
      z-index: 2;
      min-width: 315px;
      max-width: 427px;
    }
    .imac {
      width: 100%;
      max-width: 640px;
      position: absolute;
      left: 25%;
      transform: translate(-50%, 0);
      z-index: 2;
      top: 5%;
    }

    .iphone.iphone1 {
      transform: translate(-45%, -40px);
    }
    .iphone.iphone2 {
      transform: translate(0%, 0%);
    }

    .squircle {
      position: absolute;
      width: 64px;
      z-index: 1;
    }

    .squircle-1 {
      top: 3%;
      left: 7%;
    }

    .squircle-2 {
      top: 23%;
      left: 9.5%;
      width: 48px;
    }
    .squircle-3 {
      top: 38%;
      left: 5%;
    }
    .squircle-4 {
      top: 65%;
      left: 3%;
    }
    .squircle-5 {
      top: 90%;
      left: 3%;
      width: 32px;
    }
    .squircle-6 {
      top: 3%;
      right: 7%;
    }
    .squircle-7 {
      top: 38%;
      right: 5%;
    }
    .squircle-8 {
      top: 58%;
      right: 3%;
    }
    .squircle-9 {
      top: 88%;
      right: 3%;
      width: 48px;
    }

    .info-card {
      background-color: #ffffff;
      border-radius: 10px 0px 0px 10px;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
      width: 100%;
      max-width: 720px;
      margin-left: auto;
      z-index: 10;

      .mySwiper {
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            margin: 0 10px;
          }

          .swiper-pagination-bullet-active {
            background: #5bac43;
          }
        }
      }

      .info-content {
        padding: 40px;
        min-height: 440px;

        .row {
          h3 {
            font-size: 1.5rem;
            font-weight: 700;
            color: #5a626f;
          }

          h4,
          pre {
            font-size: 1rem;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 20px;
            color: #5a626f;
            line-height: 1.5rem;
          }

          h4 {
            color: rgba(90, 98, 111, 0.5);
          }
        }
      }
    }

    .subscriptions-cards-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 100;
      position: relative;

      .secondary,
      .primary {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          font-weight: 900;
        }

        .price {
          font-weight: 700;
          font-size: 1.25rem;
          color: #5bac43;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 4rem;
          }
        }
      }

      .primary {
        border-radius: 10px;
        padding: 40px 15px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
        z-index: 1;

        .benefits-container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .benefit-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            img {
              width: 2rem;
            }

            p {
              font-size: 1rem;
              font-weight: 400;
            }
          }
        }
      }

      .secondary {
        padding: 100px 60px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
      }

      .secondary.left {
        border-radius: 10px 0px 0px 10px;
      }

      .secondary.right {
        border-radius: 0px 10px 10px 0px;
      }
    }
  }

  .fourth-section-footer {
    width: 100%;
  }
  .fifth-section {
    margin: 40px 0px;
    display: flex;
    width: 100%;
    position: relative;
    min-height: 600px;
    .content {
      padding: 80px 0px 0px 80px;
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;

      .title {
        h2 {
          font-size: 2.25rem;
          line-height: 3rem;
          font-weight: 700;
          text-align: center;
          color: #5a626f;
        }
      }

      .paragraph {
        p {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: #5a626f;
        }
      }

      .footer {
        padding: 15px 20px;
        background-color: #5bac43;
        color: #ffffff;
        border-radius: 10px;
      }
    }

    .dashboard {
      position: absolute;
      width: 100%;
      max-width: 600px;
      top: 0px;
    }

    .dashboard1 {
      right: 25%;
      transform: translate(25%, 0%);
      z-index: 1;
    }

    .dashboard2 {
      right: 0%;
      transform: translate(-10%, 35%);
      z-index: 0;
    }
  }
}

.custom-factura-form-container {
  padding: 5rem 1rem 5rem 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #f6f4ef;
}

.search-text {
  color: #164e2a;
  font-variant-numeric: slashed-zero;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.step-indicator {
  color: #305126;
  font-variant-numeric: slashed-zero;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.subtitle-text {
  color: #164e2a;
  font-variant-numeric: slashed-zero;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.landing-input {
  display: flex;
  width: 100%;
  height: 3.5rem;
  padding: 15px 20px 15px 20px;
  align-items: center;
  border-radius: 20px;
  background: var(--Backgroun-beige, #f6f4ef);

  &:focus {
    outline: none;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.452);
  }
}

.landing-input-green {
  display: flex;
  width: 100%;
  height: 3.5rem;
  padding: 18px 20px 18px 20px;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  border: 2px solid #164e2a;
  background: var(--Backgroun-beige, #f6f4ef);

  &:disabled {
    color: #164e2a;
  }
}

.card-search-container {
  background-color: #fff;
  padding: 5rem;
  border-radius: 20px;
  width: 50%;
}

.wfacturas-button-landing {
  display: inline-flex;
  height: 72px;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  background: #4ca647;
  color: var(--Backgroun-beige, #f6f4ef);
  font-variant-numeric: slashed-zero;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.custom-factura-form {
  width: 40%;
  z-index: 2;
}

.distributor {
  .top-section {
    .content {
      justify-content: flex-start;
      top: 10%;

      .container {
        padding-top: 40px;
      }

      .container.image-laptop-cellphone {
        max-width: 670px;
        left: auto;
        right: 2%;
        padding: 0px;
      }
    }
  }

  .third-section {
    .content-container {
      width: 100%;
      max-width: 820px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        flex-wrap: wrap;

        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h4 {
            font-size: 1rem;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
          }

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1rem;
            font-weight: 700;

            span {
              font-size: 2.25rem;
            }
          }
        }

        .iva,
        .comision,
        .total {
          p {
            span {
              font-weight: 400;
            }
          }
        }

        .iva p {
          color: #4360ac;
        }

        .bruto p {
          color: #5bac43;
        }
        .comision p {
          color: #5bac43;
        }
        .total p {
          color: #e15241;
        }
      }

      .row.input-row {
        flex-direction: column;
        gap: 10px;
        .label-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          label {
            font-size: 0.875rem;
            color: #5a626f;
            font-weight: 400;
            max-width: 540px;
            width: 100%;
          }
          .fill {
            width: 145px;
          }
        }

        .form-container {
          display: flex;
          width: 100%;
          gap: 20px;
          justify-content: center;
          align-items: center;

          .input-container {
            width: 100%;
            max-width: 540px;
            background: #ffffff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            overflow: hidden;

            input {
              padding: 12px 18px;
              width: 100%;
              outline: none;
              font-size: 1.125rem;
            }
          }
          button {
            color: #ffffff;
            font-size: 0.875rem;
            font-weight: 400;
            background-color: #5bac43;
            border-radius: 10px;
            padding: 15px 45px;
            width: 145px;
          }
        }
      }

      .row.withdraw-container {
        justify-content: space-between;

        h4 {
          font-size: 1rem;
          font-weight: 900;
          text-align: center;
          text-transform: uppercase;
        }
        .comision-percentage {
          p {
            font-size: 2.25rem;
            color: #5bac43;
            text-align: center;
            font-weight: 700;
          }
        }

        .total-withdraw {
          padding: 20px 50px;
          background-color: #5bac43;
          border-radius: 10px;

          h4,
          p {
            text-align: center;
            color: #ffffff;
          }

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1rem;
            font-weight: 700;

            span {
              font-size: 2.25rem;
            }
          }
        }
      }

      .row.last {
        p {
          text-align: center;
          color: #5a626f;
        }
      }
    }
  }

  .fourth-section {
    .subscriptions-cards-container {
      margin-top: 20px;
      .secondary {
        padding: 40px 60px;
      }
      .primary {
        padding: 70px 0px;
        width: 100%;
        max-width: 480px;
      }
    }
  }

  .fifth-section {
    .screenshot {
      width: 100%;
      position: absolute;
      right: 8%;
    }

    .screenshot.screenshot-desktop-payment {
      max-width: 600px;
      top: 40%;
      transform: translate(0%, -50%);
    }

    .screenshot.mobile {
      max-width: 180px;
    }

    .screenshot.app-screenshot1 {
      right: 40%;
      top: 35%;
    }
    .screenshot.app-screenshot2 {
      right: 5%;
      top: 33%;
    }
  }
}

@media (max-width: 1411px) {
  .landing-main-component {
    .top-section {
      .content {
        .container.container.image-laptop-cellphone {
          width: 100%;
          max-width: 650px;
          left: 0%;
        }
      }
    }
  }
  .landing-main-component.distributor {
    .top-section {
      .content {
        .container.container.image-laptop-cellphone {
          width: 100%;
          max-width: 650px;
          right: 0%;
          left: auto;
        }
      }
    }
  }
}

@media (max-width: 1340px) {
  .landing-main-component {
    .fifth-section {
      .content {
        padding: 80px 20px 0px 20px;
        z-index: 10;

        .paragraph {
          p {
            font-weight: 500;
          }
        }
      }
      .dashboard {
        opacity: 0.4;
      }
    }
  }
}

@media (max-width: 1310px) {
  .landing-main-component {
    .top-section {
      .container.container.image-laptop-cellphone {
        img.laptop-cellphone {
          opacity: 0.15;
        }
      }
      img.background {
        height: 65vh;
      }

      img.vector-landing-top {
        height: 21vh;
        z-index: 0;
        display: none;
      }
    }
  }

  .landing-main-component.distributor {
    .top-section {
      .content {
        top: 2%;
      }
    }
  }
}

@media (max-width: 1240px) {
  .landing-main-component.distributor {
    .fifth-section {
      .screenshot {
        opacity: 0.4;
      }
    }
  }
}

@media (max-width: 1072px) {
  .landing-main-component {
    .fourth-section {
      .subscriptions-cards-container {
        flex-direction: column;
        .secondary.left {
          border-radius: 10px 10px 0px 0px;
        }

        .secondary.right {
          border-radius: 0px 0px 10px 10px;
        }
        .secondary {
          min-width: 315px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .landing-main-component {
    .fourth-section {
      .iphone {
        display: none;
      }
    }
    .fifth-section {
      .dashboard1 {
        display: none;
      }
      .dashboard2 {
        display: none;
      }
      .footer {
        margin: auto;
      }
    }
    .top-section {
      .container.image-laptop-cellphone {
        display: none;
      }
    }
  }
  .landing-main-component.distributor {
    .fourth-section {
      .imac {
        display: none;
      }
    }
    .fifth-section {
      .footer {
        margin: auto;
      }
      .screenshot.screenshot-desktop-payment {
        display: none;
      }
      .screenshot.app-screenshot1 {
        display: none;
      }
      .screenshot.app-screenshot2 {
        display: none;
      }
    }
  }
}

@media (max-width: 640px) {
  .landing-main-component.distributor {
    .third-section {
      .content-container {
        .row {
          justify-content: center;
        }
      }
    }
  }
  .custom-factura-form {
    width: 100%;
  }

  .card-search-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 20px;
    width: 100%;
  }
}
@media (max-width: 583px) {
  .landing-main-component {
    .second-section {
      .row {
        .container {
          .division {
            display: none;
          }
        }
      }
    }

    .fourth-section {
      .iphone.iphone2 {
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}

.install-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
@media (max-width: 671px) {
  .install-main-container {
    margin-top: 0;
  }
  .select-resp {
    display: flex;
    min-width: fit-content;
    flex-direction: column;
    gap: 1rem;
  }
  .new-rest-resp {
    margin: 0;
  }
  .resp-display-none {
    display: none;
  }
}

.install-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.install-card-title-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.install-text-container p {
  font-size: 0.825rem;
  margin-top: 20px;
  text-align: justify;
}
.install-card-empecemos-btn-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.install-card-empecemos-btn-container button {
  padding: 15px;
  width: 33.3333%;
  border: 2px solid #5bac43;
  background-color: transparent;
  color: #5bac43;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
  margin-top: 24px;
}

.install-card-empecemos-btn-container button:hover {
  background-color: #5bac43;
  color: #ffffff;
}

@media (max-width: 530px) {
  .install-card {
    padding: 30px;
  }
}

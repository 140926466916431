.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 675px) {
  .main-admin-page-padding {
    padding: 4.5rem 0.7rem;
  }
  .margin-remover {
    margin: 0;
    width: 100%;
  }
  .resp-link {
    display: block;
  }
}

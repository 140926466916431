.reference-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 1rem;
}

.reference-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.reference-card-title-container h2 {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.reference-amount-text-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.reference-amount-text-container img {
  max-width: 300px;
  max-height: 84px;
}

.reference-amount-container {
  text-align: center;
}

.reference-amount-container h3 {
  font-size: 1.5rem;
}

.reference-amount-container h4 {
  font-size: 2rem;
  font-weight: 700;
}

.reference-amount-container span {
  font-size: 0.8rem;
}

.reference-text-container p {
  font-size: 0.825rem;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
}

.reference-card-input-container {
  margin-top: 25px;
}

.reference-card-input-container span {
  font-size: 0.825rem;
  font-family: Arial, Helvetica, sans-serif;
}

.reference-card-input {
  margin-top: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.reference-card-input input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 446px) {
  .reference-amount-text-container {
    justify-content: center;
  }

  .reference-card-input input {
    font-size: 1.5rem;
  }
}

@media (max-width: 530px) {
  .reference-card {
    padding: 30px;
  }
}

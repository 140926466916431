.Payment-main-component {
  background-size: 100% 100%;
  min-height: 100%;
  justify-content: center;
  padding: 0px;
  background-color: #ffffff;
  background-image: url("../../../images/background-half.png");
  background-repeat: no-repeat;
}

.Payment-form-card {
  width: 100%;
  height: 450px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Payment-main-container {
  margin: auto;
  padding: 40px;
}

.Payment-content {
  display: flex;
  gap: 2px;
  justify-content: start;
  flex-direction: column;
  h1 {
    font-weight: bold;
  }

  .Payment-go-back {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #aaadb6;
  }

  .Payment-icon {
    display: flex;
  }

  .Payment-fecha {
    color: #5a626f;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .Payment-title {
    color: #2d3849;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
  }
}

.Payment-table {
  display: flex;
  justify-content: start;
  flex-direction: column;
  table {
    tr {
      border-bottom: 1px solid rgba(90, 98, 110, 0.25);
      &:first-child {
        border-bottom: 1px solid rgba(90, 98, 110, 0.5);
      }
    }
  }
  .Payment-table-content {
    .Payment-table-header {
      padding: 2ex;
      td {
        color: #5a626f;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 11px;
      }
    }

    .Payment-table-body {
      padding: 20px;
      td {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 20px;
      }
    }
  }
}
@media (max-width: 520px) {
  .Payment-main-container {
    padding: 20px 20px;
  }
  .Payment-table {
    text-align: center;
    overflow: auto;
  }
}

.personal-install-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.personal-install-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.personal-install-back-text-container a {
  font-size: 0.75rem;
  color: #aaadb6;
}

.personal-install-card-title-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.personal-install-text-container {
}

.personal-install-text-container p {
  font-size: 0.825rem;
  margin-top: 20px;
  text-align: justify;
}

.personal-install-video-container {
  margin-top: 45px;
}

.personal-install-video-container iframe {
  margin: auto;
  border-radius: 10px;
  width: 560px;
}

.personal-install-card-empecemos-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}

.personal-install-card-empecemos-btn-container button {
  padding: 15px;
  width: 33.3333%;
  border: 2px solid #5bac43;
  background-color: #5bac43;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
  margin-top: 17px;
}

.personal-install-card-empecemos-btn-container button:hover {
  background-color: transparent;
  color: #5bac43;
}

@media (max-width: 530px) {
  .personal-install-card {
    padding: 30px;
  }
}

@media (max-width: 700px) {
  .personal-install-video-container iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
    max-height: 360px;
  }
}

.profile-navigation {
  clear: both;
  min-height: 100vh;
  min-width: 280px;
  overflow: hidden;
  width: 18%;
}

.facturas-title {
  font-weight: 500;
  font-size: 36px;
  color: #0c712e;
}

.wfacturas-logo-container {
  display: flex;
  max-height: 12rem;
  border-radius: 1rem;
  overflow: hidden;
}

.wfacturas-logo {
  display: flex;
  align-items: center;
  color: #f6f4ef;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  max-width: 250px;
  border-radius: 1.5rem;
}

.wfacturas-timbres {
  display: flex;
  align-items: center;
  height: 64px;
  color: #3f2e04;
  font-size: 18px;
  font-weight: 500;
  background-color: #ceeed5;
  border-radius: 16px;
  margin: 1rem;
}

.timbres-count {
  font-weight: 600;
  font-size: 32px;
  margin-right: 0.75rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.burger-toggle {
  display: none;
}

.wfacturas-style {
  min-height: 100dvh;
  max-width: 280px;
  width: 18%;
  background-color: #345640;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.menu-button {
  display: none;
}

.wfacturas-burger {
  background-color: #345640;
}

@media (max-width: 670px) {
  .burger-toggle {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    opacity: 0;
    z-index: 999;
  }

  .menu-button {
    display: grid;
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    gap: 0.25rem;
  }

  .wfacturas-style {
    position: fixed;
    z-index: 999;
    max-width: 100%;
    width: 100%;
    height: 5rem;
    min-height: 5rem;
  }

  .burger-toggle:not(:checked) ~ .wfacturas-burger {
    display: none;
  }

  .wfacturas-logo {
    width: 50%;
  }
}

.wfacturas-items {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #f6f4ef;
  font-weight: 400;
  font-size: 20px;
  height: 70px;

  &:hover {
    background-color: #f6f4ef;
    color: #3f2e04;
  }
}

.wfacturas-active {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 20px;
  height: 70px;
  background-color: #f6f4ef;
  color: #3f2e04;
}

.svg_icons {
  transform: scale(1.25);
  margin-left: 0.3rem;
}

@media (max-width: 670px) {
  .dropdown-menu-account {
    display: none;
  }

  .menu-button-burger {
    overflow: hidden;
    max-height: 25px;
    -webkit-transition: max-height 0.5s ease;
  }

  .menu-button-burger.show {
    height: auto;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .media-h2-show {
    display: inline !important;
  }
}

@media (min-width: 671px) {
  .list-no-show {
    display: none;
  }
}

.media-h2-show {
  display: none;
}

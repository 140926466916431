.introduction-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 1rem;
}
@media (max-width: 671px) {
  .introduction-main-container {
    margin-top: 0;
  }
}

.introduction-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.introduction-card-title-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.introduction-text-container {
}

.introduction-text-container p {
  font-size: 0.825rem;
  margin-top: 20px;
  text-align: justify;
}

.introduction-video-container {
  margin-top: 45px;
}

.introduction-video-container iframe {
  margin: auto;
  border-radius: 10px;
  width: 100%;
  max-width: 360px;
  height: 240px;
}

.introduction-card-empecemos-btn-container {
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.introduction-card-empecemos-btn-container button {
  padding: 15px;
  width: 33.3333%;
  border: 2px solid #89ca75;
  background-color: #89ca75;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
  margin-top: 24px;
}

.introduction-card-verificar-btn-container button:hover {
  background-color: transparent;
  color: #89ca75;
}

.introduction-card-reenviar-container {
  margin-top: 45px;
}

.introduction-card-reenviar-container p {
  font-size: 0.825rem;
  font-weight: 400;
}

.introduction-card-reenviar-container button {
  padding: 15px;
  width: 33.3333%;
  margin-top: 20px;
  border: 2px solid #89ca75;
  background-color: transparent;
  color: #89ca75;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
}

.introduction-card-reenviar-container button:hover {
  background-color: #89ca75;
  color: #ffffff;
}

@media (max-width: 530px) {
  .introduction-card {
    padding: 30px;
  }

  .introduction-card-verificar-btn-container {
    justify-content: center;
  }

  .introduction-card-reenviar-container {
    text-align: center;
  }
}

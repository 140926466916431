html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
}

body #root {
  height: 100%;
}

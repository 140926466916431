.collaborator-install-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.collaborator-install-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.collaborator-install-back-text-container a {
  font-size: 0.75rem;
  color: #aaadb6;
}

.collaborator-install-card-title-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.collaborator-install-text-container {
  margin-top: 20px;
}

.collaborator-install-text-container p {
  font-size: 0.825rem;
  text-align: justify;
}

.collaborator-install-card-input-container {
  margin-top: 45px;
}

.collaborator-install-card-input-container span {
  font-size: 0.825rem;
  margin-top: 20px;
}

.collaborator-install-card-input {
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.collaborator-install-card-input input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
}

.collaborator-install-card-empecemos-btn-container {
  display: flex;
  margin-top: 45px;
  justify-content: flex-end;
}

.collaborator-install-card-empecemos-btn-container button {
  padding: 15px;
  width: 33.3333%;
  border: 2px solid #5bac43;
  background-color: #5bac43;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
}

.collaborator-install-card-empecemos-btn-container button:hover {
  background-color: transparent;
  color: #5bac43;
}

.collaborator-install-card-reenviar-container {
  margin-top: 45px;
}

.collaborator-install-card-reenviar-container p {
  font-size: 0.825rem;
  font-weight: 400;
}

.collaborator-install-card-reenviar-container button {
  padding: 15px;
  width: 33.3333%;
  margin-top: 20px;
  border: 2px solid #89ca75;
  background-color: transparent;
  color: #89ca75;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 200px;
  font-size: 0.825rem;
}

.collaborator-install-card-reenviar-container button:hover {
  background-color: #89ca75;
  color: #ffffff;
}

@media (max-width: 530px) {
  .collaborator-install-card {
    padding: 30px;
  }

  .collaborator-install-card-empecemos-btn-container {
    justify-content: center;
  }

  .collaborator-install-card-reenviar-container {
    text-align: center;
  }
}

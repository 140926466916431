.distributor-dashboard-page-main-container {
  min-height: 100%;
  background-image: url("../../../images/background-full.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;

  .distributor-dashboard-card-component {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
    padding: 20px 40px;

    .header {
      h2 {
        font-size: 1.5rem;
        font-weight: 800;
        color: #2d3849;
      }
    }
  }

  .referred-card {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .referred-link-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        gap: 5px;

        p {
          font-size: 0.875rem;
          line-height: 0.875rem;
          font-weight: 400;
          color: #5a626f;

          b {
            font-weight: 600;
          }
        }

        img {
          width: 1.5rem;
        }
        .referred-link-message {
          margin: 10px;
          color: #5bac43;
          transition: all;
          transition-duration: 300;
          border-bottom: 2px solid #5bac43;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
            inset 0 0 10px rgba(0, 0, 0, 0.07);
        }
      }

      .download-button-container {
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          overflow: hidden;
          border: 1px solid #5bac43;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
            inset 0 0 10px rgba(0, 0, 0, 0.07);
          color: #5bac43;
          padding: 10px 15px;
          border-radius: 10px;
          outline: none;
          &:focus,
          &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .billing-card {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 10px;

      p {
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 500;
        color: #5a626f;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .container {
        width: 50%;
      }

      .billing-info-container {
        width: auto;
        .row {
          display: flex;
          justify-content: space-between;
          max-width: 400px;

          .column {
            width: 100%;
            min-width: 180px;
            p {
              text-align: left;
              font-size: 0.875rem;
              color: #5a626f;
              font-weight: 400;
            }
          }

          .column.payment {
            p {
              color: #5bac43;
              span {
                font-size: 2rem;
                font-weight: 600;
              }
            }
          }

          .column.right-text {
            p {
              font-weight: 600;
            }
          }

          .column.description {
            width: auto;
          }
        }
      }

      .balance-info-main-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 40px;
        padding-right: 30px;
        min-width: fit-content;
        flex-wrap: wrap;

        .balance-container {
          width: 260px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          p {
            font-size: 0.875rem;
            font-weight: 600;
            color: #5a626f;
          }

          .header {
            p {
              font-weight: 400;
            }
          }

          .balance-row {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .amount-container.total {
              p {
                color: #5bac43;
              }
            }

            .amount-container.comision {
              p {
                color: #e15241;
              }
            }
          }

          .balance-row.total {
            border-top: 1px solid rgba(90, 98, 111, 0.5);
            padding-top: 5px;
          }
        }

        .chart {
          width: 290px;
          height: 200px;
          margin-top: -20px;

          .distributor-dashboard-chart {
            padding: 0;
            overflow: initial;
            margin: 0;
            box-shadow: none;
            border: none;

            div {
              margin: 0;
            }
            svg {
              overflow: initial;
            }
          }
        }
      }
    }
  }
  .payments-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .header {
      h2 {
        padding-bottom: 25px;
      }
      p {
        font-family: "Raleway";
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #5a626f;
      }
    }
    .distributor-dashboard-Select-fecha {
      width: 240px;
      height: 48px;
      padding: 10px;
      background: #ffffff;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
        inset 0 0 10px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      &:focus,
      &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }
      select {
        width: 100%;
        outline: none;
        cursor: pointer;
        text-overflow: "";
        text-indent: 5px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #2d3849;
      }
    }
    .distributor-dashboard-table {
      display: flex;
      justify-content: start;
      flex-direction: column;
      table {
        tr {
          border-bottom: 1px solid rgba(90, 98, 110, 0.25);
          &:first-child {
            border-bottom: 1px solid rgba(90, 98, 110, 0.5);
          }
        }
      }
      .distributor-dashboard-table-content {
        .distributor-dashboard-table-header {
          td {
            color: #5a626f;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 11px;
          }
        }
        .distributor-dashboard-table-body {
          td {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding: 20px;
          }
          .distributor-dashboard-table-button {
            box-sizing: border-box;
            width: 127px;
            height: 34px;
            border: 1px solid #5bac43;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
              inset 0 0 10px rgba(0, 0, 0, 0.07);
            &:focus,
            &:hover {
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            }
            outline: none;
            cursor: pointer;

            .distributor-dashboard-table-button-content {
              height: 16px;
              font-family: "Raleway";
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 15px;
              color: #5bac43;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 780px) {
  .distributor-dashboard-page-main-container {
    .distributor-dashboard-card-component {
      .content {
        .referred-link-container {
          img {
            margin-right: 25%;
          }
        }
        .download-button-container {
          button {
            margin: auto;
          }
        }
      }
    }
    .billing-card {
      .content {
        .billing-info-container {
          margin-bottom: 40px;
        }
        .balance-info-main-container {
          gap: 100px;
          padding-right: 0px;
          .balance-container {
            width: 260px;
            gap: 5px;
          }
          .chart {
            width: 210px;
            height: 200px;
            margin-top: -20px;
          }
        }
      }
    }
  }
}
@media (max-width: 730px) {
  .distributor-dashboard-page-main-container {
    .distributor-dashboard-card-component {
      .content {
        display: block;
        gap: 100px;
        .referred-link-container {
          text-align: center;
          margin-left: 20%;
          padding-bottom: 25px;
          img {
            margin: auto;
          }
        }
        .download-button-container {
          button {
            margin: auto;
          }
        }
      }
    }
    .billing-card {
      .content {
        align-items: center;
        justify-content: center;
        .billing-info-container {
          margin-bottom: 10%;
          .row {
            .column.payment {
              p {
                text-align-last: end;
              }
            }
            .column.right-text {
              p {
                text-align: end;
              }
            }
          }
        }
        .balance-info-main-container {
          align-items: center;
          justify-content: center;
        }
      }
    }
    .distributor-dashboard-table {
      text-align: center;
      overflow: auto;
    }
  }
}
@media (max-width: 500px) {
  .distributor-dashboard-page-main-container {
    padding: 20px;
    .distributor-dashboard-card-component {
      .content {
        .referred-link-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          margin: 0%;
          p {
            font-size: 12px;
          }
          img {
            display: none;
          }
        }
        .download-button-container {
          button {
            font-size: 10px;
            margin: auto;
          }
        }
      }
    }
    .billing-card {
      .header {
        display: block;
        h2 {
          padding-bottom: 25px;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        .billing-info-container {
          margin-bottom: -25%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          .row {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .column.description {
              p {
                font-size: small;
              }
            }
            .column.payment {
              p {
                text-align-last: end;
                span {
                  font-size: 1.2rem;
                }
                font-size: 0.875rem;
              }
            }
            .column.right-text {
              p {
                text-align: end;
              }
            }
          }
        }
        .balance-info-main-container {
          display: grid;
          .balance-container {
            width: -webkit-fill-available;
            margin-bottom: -25%;
          }
          .chart {
            margin-bottom: -20%;
            .distributor-dashboard-chart {
              div {
                width: 150px;
                margin-left: 8px;
              }
              font-stretch: ultra-condensed;
            }
          }
        }
      }
    }
    .payments-card {
      padding: 20px 20px;
      .distributor-dashboard-Select-fecha {
        display: flex;
        align-self: flex-start;
        padding: 0px;
        width: 140px;
        height: 28px;
        select {
          font-size: 12px;
        }
      }
    }
    .distributor-dashboard-table {
      text-align: center;
      overflow: auto;
    }
  }
}

.back-to-admin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #ffff;
  background-color: #21840e;
  font-weight: 600;
  border-style: none;
}

@media (max-width: 670px) {
  .myaccount-main-container {
    flex-wrap: wrap;
  }
  .dashboard-resp {
    padding: 5rem 1rem 1rem 1rem;
  }

  .profile-navigation {
    min-height: auto;
    height: auto;
    min-width: 280px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;
  }

  .menu-account {
    width: 100%;
    text-align: center;
    padding: 0 !important;
  }
}

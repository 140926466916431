.wmobil-install-background {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #89ca75;
  background-image: url("../../images/back5.png");
  z-index: -5;
  background-position: center;
  background-size: cover;
}

.wmobil-install-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.wmobil-install-card {
  background-color: #ffffff;
  padding: 65px;
  border-radius: 10px;
  max-width: 770px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.wmobil-install-back-text-container a {
  font-size: 0.75rem;
  color: #aaadb6;
}

.wmobil-install-card-title-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.wmobil-install-text-container {
}

.wmobil-install-text-container p {
  font-size: 0.825rem;
  margin-top: 20px;
  text-align: justify;
}

.wmobil-install-video-container {
  margin-top: 45px;
}

.wmobil-install-video-container iframe {
  margin: auto;
  border-radius: 10px;
  width: 560px;
}

.wmobil-install-card-empecemos-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.wmobil-install-card-empecemos-btn-container button {
  padding: 15px;
  width: 33.3333%;
  border: 2px solid #5bac43;
  background-color: #5bac43;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 260px;
  font-size: 0.825rem;
}

.wmobil-install-card-empecemos-btn-container button:hover {
  background-color: transparent;
  color: #5bac43;
}

.wmobil-install-card-bottom-text-container {
  margin-top: 40px;
}

.wmobil-install-card-bottom-text-container p {
  font-size: 0.825rem;
  font-weight: bold;
}

.wfacturas-logo-screen {
  width: 15rem;
  height: 15rem;
}

.wfacturas-screen {
  display: flex;
  height: 100svh;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 530px) {
  .wmobil-install-card {
    padding: 30px;
  }
}

@media (max-width: 700px) {
  .wmobil-install-video-container iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
    max-height: 360px;
  }
}

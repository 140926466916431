.wfacturas-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-sizing: 5px 5px 54px rgba(0, 0, 0, 0.25);
  width: 32rem;
  position: relative;
  border-radius: 15px;
  background-color: #fdfbf8;
  outline: none;

  .w-title-container {
    font-weight: 700;
    font-size: 1.25rem;
    color: #3f2e04;
    padding: 1rem;
  }

  .w-body-container {
    font-weight: 500;
    font-size: 1rem;
    color: #3f2e04;
    padding: 1rem;
  }

  .w-buttons-container {
    display: flex;
    justify-content: end;
    gap: 2rem;
    padding: 1rem;
    background-color: #efebdf;
    border-radius: 0 0 15px 15px;
  }

  .w-buttons-container-partial {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0 2rem 0;
    border-radius: 0 0 15px 15px;
  }
}

.wfacturas-modal-invoice {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-sizing: 5px 5px 54px rgba(0, 0, 0, 0.25);
  width: 50%;
  position: relative;
  border-radius: 15px;
  background-color: #fdfbf8;
  outline: none;

  .w-title-container {
    font-weight: 700;
    font-size: 1.25rem;
    color: #3f2e04;
    padding: 1rem 1rem 0 1rem;
  }

  .w-body-container {
    font-weight: 500;
    font-size: 1rem;
    color: #3f2e04;
    padding: 1rem;
    scrollbar-width: thin;
    max-height: 80dvh;
    overflow-y: auto;
  }

  .w-buttons-container {
    display: flex;
    justify-content: end;
    gap: 2rem;
    padding: 1rem;
    background-color: #efebdf;
    border-radius: 0 0 15px 15px;
  }
}

.wfacturas-modal-loader {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-sizing: 5px 5px 54px rgba(0, 0, 0, 0.329);
  position: relative;
  outline: none;
}

.info-icon {
  position: absolute;
  right: 0;
  top: -1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .wfacturas-modal-invoice {
    width: 90%;

    .w-body-container {
      max-height: 65dvh;
      overflow-y: auto;
    }
  }
}

.select-plan {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

.select-plan:hover {
  border: 2px solid #5bac43;
}

.select-plan .plan-option-price {
  color: #5bac43;
}

.select-plan.active {
  background-color: #5bac43;
  border: 1px solid #5bac43;
  color: white;
}

.select-plan.active .plan-option-price {
  color: white;
}

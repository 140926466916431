.table-icon-no-resp {
  margin-right: 60%;
  text-align: left;
  display: flex;
}

@media (max-width: 674px) {
  .rm-margin-resp {
    margin-right: 1rem;
  }
  .text-resp {
    font-size: 1rem;
  }
}

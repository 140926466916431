.layout-v2-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.custom-date-select {
  display: flex;
}
@media (max-width: 770px) {
  .custom-date-select {
    flex-direction: column;
  }
}

.navbar-v2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding: 0px 45px;
  z-index: 10;
  background-color: #ffffff;

  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;

      img {
        width: 2.5rem;
      }

      h3 {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    a.active li h3 {
      text-decoration: underline;
      color: #5bac43;
    }
  }

  .navlinks-container.resp {
    display: none;
  }
}

.navbar-v2-landing {
  position: absolute;
  width: 100%;
}

@media (max-width: 690px) {
  .navbar-v2 {
    padding: 0.5rem;
    position: fixed;
    width: 100%;
    z-index: 999;

    .navlinks-resp {
      display: none;
    }

    ul {
      li {
        padding: 0.25rem;
      }
    }

    .navlinks-container.noresp {
      opacity: 0;
    }

    .navlinks-default {
      display: none;
    }

    .navlinks-container.resp {
      display: flex;

      .menu-button-burger {
        position: fixed;
        right: 1rem;
        top: 1rem;
        overflow: hidden;
        max-height: 25px;

        .cancel-top-right-corner {
          display: flex;
          position: inherit;
          flex-direction: column;
          align-items: end;

          .position-top-right {
            display: block;
            position: relative;
            top: 0.5rem;
            left: 0.5rem;
          }

          .nav-link-flex-box {
            display: flex;
            gap: 1rem;
          }
        }
      }

      .menu-button-burger.show {
        position: inherit;
        height: auto;
        max-height: 500px;
        overflow: hidden;
        transition: max-height 0.5s ease;
      }
    }
  }
}

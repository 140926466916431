.payment-methods-container-new-subscription {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.payment-methods-container-new-subscription button {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 10px;
  width: 24%;
  min-width: 150px;
  /* padding: 10px 0; */
  font-size: 0.875rem;
  font-weight: 700;
  color: #5bac43;
  border: 2px solid transparent;
  outline: none;
}

.payment-methods-container-new-subscription button:hover {
  border: 2px solid #5bac43;
}

.payment-methods-container-new-subscription button.active {
  background-color: #5bac43;
  color: #ffffff;
}

.payment-methods-container-new-subscription button img {
  width: 70px;
  height: 30px;
  margin: auto;
}

@media (max-width: 729px) {
  .payment-methods-container-new-subscription button {
    margin-top: 1rem;
  }
}

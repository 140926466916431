.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;

  .active {
    font-weight: bold;
    font-size: larger;
  }

  .buttons {
    button {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      border: none;
      margin-right: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .rest {
    display: flex;
    align-items: center;

    div {
      margin-right: 1rem;
      font-size: 12px;
    }

    select {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      border: none;
    }
  }
}
.folios-table-bg {
  background-color: #f6f4ef;
}

.wbg-green-logo {
  background-color: #0c712e;
}
.wbg-brown-light {
  background-color: #efebdf;
}

.wborder-green-logo {
  border-color: #0c712e;
}

.removed-afcolor:-webkit-autofill,
.removed-afcolor:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.plans-banner-text {
  color: #422504;
  font-size: 8rem;
  font-weight: 900;
  // text-shadow: 2px 0px 2px #3f2e04;
}
@media (max-width: 765px) {
  .plans-banner-text {
    font-size: 6rem;
  }
}

.plans-banner-subtitle {
  color: #42250486;
  font-size: 2rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  font-weight: 400;
  // text-shadow: 1px 1px 4px #3f2e0467;
}
@media (max-width: 765px) {
  .plans-banner-subtitle {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }
}

.wfacturas-table-text {
  color: #3f2e04;
}
.custom-item-prev-container {
  max-height: 20rem;
}

.highlighted-row {
  background-color: rgb(248, 176, 176);
}
.align-center {
  align-items: center;
}

.langbtn-op-pad {
  bottom: 10.5rem;
}

.langbtn {
  bottom: 5rem;
}
.wfacturas-input-container {
  max-width: 33%;
}

.winput-folios {
  max-height: 10rem;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.wfacturas-input {
  border-width: 1.8px;
  border-color: #3f2e04;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  min-height: 3.25rem;

  &:focus {
    outline: 0;
    border-width: 2px;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.329);
    background-color: #fff;
  }
}

.wfacturas-input-big {
  border-width: 1.8px;
  border-color: #3f2e04;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  min-height: 4.5rem;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  resize: none;
  scrollbar-width: thin;
}

.wfacturas-input-invisible {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  padding: 0.5rem;
  width: 85%;
  min-height: 2.25rem;

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.329);
    background-color: #fff;
  }
}

.wfacturas-currency-input {
  border-width: 1.8px;
  border-color: #3f2e04;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  width: 100%;
  min-height: 3rem;

  &:disabled {
    color: rgba(0, 0, 0, 0.329);
    background-color: #fff;
  }
}

.wfacturas-file-input {
  border-width: 1.8px;
  border-color: #3f2e04;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  width: 100%;
  min-height: 3rem;
}

.wfacturas-search-input {
  border-width: 1.8px;
  border-color: #3f2e04;
  border-radius: 5px;
  padding: 0 2rem 0 0.5rem;
  width: 100%;
  min-height: 2.5rem;

  &:focus {
    outline: 0;
    border-width: 2px;
  }
}

.wfacturas-currency-icon {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  color: #164e2a;
  font-weight: 600;
}

.wfacturas-file-icon {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  border-radius: 1.25rem;
  width: 1.65rem;
}

.wfacturas-search-icon {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  color: #7e7061;
  font-weight: 600;
}

.wfacturas-input-label {
  display: flex;
  position: absolute;
  left: 0;
  top: -0.6rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: #fff;

  .mandatory {
    display: flex;
    position: inherit;
    top: -0.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    right: 0;
    color: rgb(207, 0, 0);
  }
}

.wfacturas-input-label-green {
  display: flex;
  position: absolute;
  left: 0;
  top: -0.6rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 45px;
  background: #164e2a;
  color: white;
  padding: 0.15rem 0.5rem 0.15rem 0.5rem;
}

.wdropdown {
  position: absolute;
  width: 100%;
  max-height: 20rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  background-color: #fff;
  z-index: 10;
  overflow-y: scroll;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

@media (max-width: 765px) {
  .main-admin-page-users-table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    font-size: 0.75rem;
  }

  .langbtn-op-pad {
    bottom: 8.5rem;
  }

  .langbtn {
    bottom: 4rem;
  }
}

.notification__badge {
  position: relative;
  top: -1.15rem;
  right: -6.4rem;
  width: 25px;
  height: 25px;
  background: #ce0210;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.9;
}
.no_notifications {
  display: none;
}

@media (max-width: 675px) {
  .notification__badge {
    display: flex;
    position: absolute;
    top: 3.2rem;
    right: 5rem;
  }
}

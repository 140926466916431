.mysub-btn-pay-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.mysub-btn-pay-container button {
  margin-top: 10px;
  padding: 8px;
  width: 33.3333%;
  border: 2px solid #89ca75;
  background-color: #89ca75;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  min-width: 100px;
  max-width: 150px;
  font-size: 1rem;
}
.page-padding {
  padding: 1rem;
}
.mysub-btn-pay-container button:hover {
  background-color: #ffffff;
  color: #89ca75;
}

@media (max-width: 671px) {
  .padding-resp {
    padding: 5rem 2rem;
  }
  .title-padding {
    margin-left: -1rem;
  }
}

.auth-links {
  border-left: 1px solid black;
}
.home-image-z-index {
  z-index: 1001;
}

@media (max-width: 675px) {
  .fixed-navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
}

.new-sub-input {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 1rem;
  height: 48px;
}

.card-expanded {
  display: none;
}

.cancel-factura-btn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: #cacaca;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #ffff;
  background-color: #bd0101;
  font-weight: 600;
}
.new-sub-btn {
  background-color: #5bac43;
  width: 50%;
  align-self: center;
}

.btn-canjear-cupon {
  background-color: #5bac43;
  border-radius: 10px;
  padding: 0.5rem 1.25rem;
  margin-left: 1rem;
  color: white;
  font-weight: bold;
}

.banner-w {
  width: 250px;
}
@media (max-width: 671px) {
  .banner-w {
    width: auto;
  }
  .flex-resp {
    flex-direction: column;
    align-items: flex-start;
  }
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #5bac43;

  animation: spin 0.75s linear infinite;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.buttonA {
  width: 25% !important;
  font-size: 12px;
}
.buttonB {
  width: 25%;
  font-size: 12px;
  margin-top: 0 !important;
}
.formA {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.spacingTop {
  margin-top: 20px;
}
.inputA {
  margin-right: 15px !important;
  height: 100% !important;
}

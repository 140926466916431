.smallButton {
  width: 10% !important;
  font-size: 12px !important;
  height: max-content;
}

.report-button {
  color: #21840e;
  font-weight: bold;
  outline: none;
}

.wfacturas-button {
  display: flex;
  background-color: #0c712e;
  font-weight: bold;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  color: #ffff;
  outline: none;
  align-items: center;
  justify-content: center;
}

.wfacturas-button:hover {
  background-color: #0d7e33;
}

.wfacturas-button-off {
  display: flex;
  background-color: #efebdf;
  font-weight: bold;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  color: #3f2e04;
  outline: none;
  align-items: center;
  justify-content: center;
}

.wfacturas-button-off:hover {
  background-color: #efebdfce;
}

.wfacturas-button-outline {
  display: flex;
  font-weight: 500;
  border-radius: 8px;
  border: 2px solid #8a6b47;
  padding: 8px 12px 8px 12px;
  color: #8a6b47;
  outline: none;
  align-items: center;
  justify-content: space-between;
}

.wfacturas-button-outline:hover {
  background-color: #f5eedbc7;
}

.wfacturas-button-outline-green {
  display: flex;
  font-weight: 600;
  border-radius: 8px;
  border: 2px solid #0c712e;
  padding: 8px 12px 8px 12px;
  color: #0c712e;
  outline: none;
  align-items: center;
  justify-content: space-between;
}

.wfacturas-button-outline-green:hover {
  background-color: rgba(240, 234, 216, 0.219);
}
.report-button:focus {
  outline: none;
}

@media (max-width: 671px) {
  .input-wrapper-resp {
    flex: none;
  }
  .button-main-resp {
    width: 100% !important;
  }
}

footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  padding: 40px;
  padding-bottom: 15px;
  margin-top: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .image-container {
      width: 200px;
    }

    .image-container.two {
      display: flex;
      justify-content: flex-end;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      li {
        font-size: 0.875rem;
        font-weight: 700;
      }
    }
  }

  .bottom-container {
    h3 {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.login-main-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  padding: 35px;
  background-image: url("../../../images/background-half.png");
  background-repeat: no-repeat;
  background-size: 50% 100%;

  .login-form-card {
    width: calc(50% - 35px);
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 105px 25px;
    min-width: 320px;

    img {
      margin: auto;
    }

    .login-title {
      margin-top: 60px;
      font-weight: 800;
      font-size: 1.5rem;
      color: #2d3849;
      text-align: center;
    }

    form {
      label {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #5a626f;
        margin-bottom: 10px;
      }

      .register-btn,
      .login-btn {
        width: auto;
        height: auto;
        padding: 15px 15px;
        width: 100%;
        max-width: 300px;
        min-width: 250px;
        display: block;
        margin: auto;
      }

      .login-btn {
        background-color: #5bac43;
        margin-top: 40px;
      }

      .register-btn {
        color: #5bac43;
        border: 2px solid #5bac43;
        margin-top: 20px;
        background-color: #ffffff;
      }
    }
  }
}

@media (max-width: 992px) {
  .login-main-container {
    background-size: 100% 100%;
    justify-content: center;
    padding: 15px;

    .login-form-card {
      width: 100%;
    }
  }
}
